.vorgaben-container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 148px);
    margin-top: 40px;
}

.vorgaben-liste {
    width: 360px;
    overflow-y: auto;
    margin: 0;
    padding: 0 40px 0 0;
    border-right: 1px solid rgba(75, 84, 91, 1);
}

.vorgaben-details {
    flex: 1;
    overflow-y: auto;
    margin: 0;
    padding: 10px 0 0 40px;
    color: rgba(255, 255, 255, 1);
}

.label {
    display: block;
    font-size: 0.8em;
    font-weight: 600;
    margin: 40px 0 10px 0;
    color: rgba(161, 179, 195, 1);
}

.vorgaben-liste ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.vorgaben-liste li {
    color: rgba(161, 179, 195, 1);
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 5px;
}

.vorgaben-liste li:hover {
    background-color: rgba(75, 84, 91, 1);
}

.tag-liste {
    padding: 2px 4px;
    margin-left: 10px;
    font-size: 0.6em;
}