.constraints-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 381px);
}

.constraints-content {
    flex: 1; /* Nimmt den verbleibenden Platz ein */
    overflow-y: auto; /* Erlaubt das Scrollen innerhalb des Elements */
}

.constraints-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.constraints-content ul li {
    cursor: pointer;
    padding: 10px 0;
    color: rgba(255, 255, 255, 0.8);
    border-top: 1px solid rgba(161, 179, 195, 0.5);
    display: grid;
    grid-template-columns: 20px 1fr; /* 2 Spalten */
    gap: 10px;
}