/* FullCalendar-Stile */

.ergebnis-calendar-container .fc thead a {
    font-size: 14px;
    color: initial;
    border: initial;
    text-transform: initial;
}

.ergebnis-calendar-container .fc table, .ergebnis-calendar-container .fc td {
    margin: unset;
    padding: unset;
}

/* Basis-Layout für Kalenderansicht */

.ergebnis-container {
    display: flex;
    position: relative;
    overflow-x: hidden;
}

.ergebnis-calendar-container {
    flex-grow: 1;
    border-radius: 5px;
}

.ergebnis-calendar-container h2, .ergebnis-calendar-container h3 {
    color: rgba(49, 56, 61, 1);
}

.fc-view-harness.fc-view-harness-active {
    height: 710px !important;
}


.ergebnis-event-details {
    position: absolute;
    right: -400px;
    width: 300px;
    transition: right 0.3s ease, opacity 0.3s ease;
    background-color: rgba(49, 56, 61, 0.98);
    border-radius: 5px;
    top: 0;
    bottom: 0;
    padding: 40px;
    color: rgba(255, 255, 255, 1);
    overflow-y: auto;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3), 0 2px 16px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    opacity: 0;
}

.ergebnis-event-details h3 {
    color: rgba(161, 179, 195, 1);
    margin: 0 0 10px 0;
    padding: 0 40px 0 0;
}

.ergebnis-event-details.open {
    right: 0;
    opacity: 1;
}

/* Stile für Textelemente innerhalb der Detailansicht */

.event-details p.location {
    color: rgba(161, 179, 195, 1);
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.8em;
    margin-top: 0;
    margin-bottom: 40px;
}

.event-details p label {
    margin: 0 0 5px 0;
}

.event-details a {
    color: rgba(255, 255, 255, 1);
    text-decoration-color: rgba(255, 255, 255, 0.5);
    text-underline-offset: 0.3em;
    text-decoration-thickness: 0.5px;
}

.event-details a:hover {
    text-decoration-color: rgba(255, 255, 255, 1);
}
