.color-empty { fill: rgba(255, 255, 255, 0.6); background-color: rgba(255, 255, 255, 0.6); }
.color-scale-1 { fill: #e0f7fa; background-color: #e0f7fa; }
.color-scale-2 { fill: #b2ebf2; background-color: #b2ebf2; }
.color-scale-3 { fill: #80deea; background-color: #80deea; }
.color-scale-4 { fill: #26c6da; background-color: #26c6da; }
.color-scale-5 { fill: #ffd54f; background-color: #ffd54f; }
.color-scale-6 { fill: #ffb74d; background-color: #ffb74d; }
.color-scale-7 { fill: #ff8a65; background-color: #ff8a65; }
.color-scale-8 { fill: #ff5722; background-color: #ff5722; }

.react-calendar-heatmap text {
    font-size: 9px;
}

.react-calendar-heatmap {
    box-sizing: border-box;
    display: block;
}

.calendar-heatmap-container {
    width: 100%;
    box-sizing: border-box;
    margin-top: 30px;
    padding: 30px 0 30px 30px;
    border-radius: 10px;
    background-color: rgba(161, 179, 195, 0.2);
    display: inline-block;
}

.calendar-heatmap-container h4 {
    margin: 0 0 30px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(161, 179, 195, 0.5);
}

.calendar-heatmap-container p {
    margin: 0 0 30px 0;
}

.daily-subject-workload-heatmap text {
    font-size: 12px;
    fill: #aaa;
}

/* Legende */

.heatmap-legend {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Stellt sicher, dass nicht mehr als zwei Spalten erstellt werden, wenn die Mindestbreite von 150px erreicht ist */
    gap: 0 20px; /* Vertikaler und horizontaler Abstand zwischen den Items */
    align-items: start;
    margin-top: 25px;
}

.legend-item {
    display: flex;
    align-items: center;
    color: rgba(49, 56, 61, 0.5);
    font-size: 12px;
}

.legend-color {
    width: 12px;
    height: 12px;
    border-radius: 6px; /* Abgerundete Ecken */
    margin-right: 10px; /* Abstand zwischen Farbquadrat und Text */
}



