.scrollable-div {
    font-family: 'Consolas', 'Monaco', 'Courier New', monospace; /* Code-Schriftart hinzufügen */
    font-size: 11px;
    height: 200px;
    overflow-y: auto;
    background-color: rgba(161, 179, 195, 0.2);
    margin-top: 20px;
    padding: 20px;
    border-radius: 5px;
}
.course-detail {
    border-bottom: 1px solid #ccc;
    padding: 4px 0;
    display: flex;
    align-items: center;  /* Zentriert den Inhalt vertikal */
}

.course-detail span.line {
    min-width: 40px; /* Stellt sicher, dass die Nummern genug Platz haben */
    display: inline-block; /* Erzeugt ein Block-ähnliches Verhalten für Nummerierung */
    margin-right: 10px; /* Fügt Abstand nach der Nummer hinzu */
}

.course-detail span.group {
    min-width: 80px; /* Stellt sicher, dass die Nummern genug Platz haben */
    display: inline-block; /* Erzeugt ein Block-ähnliches Verhalten für Nummerierung */
    margin-right: 10px; /* Fügt Abstand nach der Nummer hinzu */
    font-weight: 600;
}
